import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  MenuItem,
  Collapse,
  Stack,
  Typography,
} from '@mui/material';
import { Tab, Tabs } from '@watershed/ui-core/components/Tab';
import AddIcon from '@watershed/icons/components/Add';
import { useAllOrganizationsQuery } from '@watershed/shared-frontend/generated/urql';
import isFetchingOrStale from '@watershed/shared-frontend/utils/isFetchingOrStale';
import { useQueryParam } from '@watershed/shared-frontend/utils/queryParamHooks';
import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';
import Button from '@watershed/ui-core/components/Button';
import gql from 'graphql-tag';
import dynamic from 'next/dynamic';
import { useRef, useState } from 'react';
import AdminPageContainer from '../components/AdminPageContainer';
import OrgQuickLinks from '../components/OrgQuickLinks';
import {
  adminLoginAsMyselfUrl,
  adminLoginAsUrl,
  urlForObject,
} from '@watershed/shared-universal/adminRoutes';
import { getGqlResultData } from '@watershed/shared-frontend/utils/errorUtils';
import { LoginAsButtons } from '../components/LoginAsButtons';
import { useOwner } from '@watershed/shared-frontend/hooks/useOwner';
import { Teams } from '@watershed/constants/teams';
import {
  GridColDef,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from '@watershed/ui-core/components/DataGrid/DataGrid';
import {
  GQOrganizationListPageOrganizationFragment,
  GQWatershedPlanLegacy,
} from '@watershed/shared-universal/generated/graphql';
import CopyIcon from '@watershed/icons/components/Copy';
import copyToClipboard from 'copy-to-clipboard';
import useSnackbar from '@watershed/shared-frontend/hooks/useSnackbar';
import MoreActionsButton from '@watershed/ui-core/components/MoreActionsButton';
import useKeydown from '@watershed/shared-frontend/hooks/useKeydown';
import {
  FavoriteOrgButton,
  useFavoriteOrgs,
  useReferenceOrgs,
} from '../utils/referenceOrgUtils';
import ShareIcon from '@watershed/icons/components/Share';
import UploadIcon from '@watershed/icons/components/Upload';
import Callout from '@watershed/shared-frontend/components/Callout';
import WarningIcon from '@watershed/icons/components/Warning';
import CreateDemoOrgDialog from '@watershed/shared-frontend/components/entFound/CreateDemoOrgDialog';
import { useAdminContext } from '../components/AdminContext';
import { ObjectList } from '@watershed/ui-core/components/ObjectList/ObjectList';
import {
  navigateToPathOnCallback,
  navigateToPathOnClick,
} from '@watershed/ui-core/utils/NavigationUtils';
import { useRouter } from 'next/router';
import { getOverflowMenuColumn } from '@watershed/ui-core/components/ObjectList/ObjectListOverflowMenu';
import { Pill } from '@watershed/ui-core/components/Pill';
import { getCurrentDevEnv } from '@watershed/shared-frontend/utils/devEnv';
import { previewDeployNames } from '@watershed/shared-universal/utils/helpers';
import OverflowChipList from '@watershed/shared-frontend/components/OverflowChipList';
import useToggle from '@watershed/ui-core/hooks/useToggle';
import ChevronUpIcon from '@watershed/icons/components/ChevronUp';
import ChevronDownIcon from '@watershed/icons/components/ChevronDown';
import TextLink from '@watershed/ui-core/components/TextLink';

gql`
  fragment OrganizationListPageOrganization on Organization {
    id
    name
    domains
    demoOrg
    testOrg
    stagingOrg
    canAccessFinance
    hasUsers
    watershedPlanLegacy
  }

  query AllOrganizations {
    organizations {
      edges {
        node {
          __typename
          ...OrganizationListPageOrganization
        }
      }
    }
  }
`;

const AddOrganizationDialog = dynamic({
  loader: () => import('../components/AddOrganizationDialog'),
});

type DialogKind = 'createOrg' | 'createDemoOrg';

function DialogController({
  kind,
  ...props
}: {
  kind: DialogKind | null;
  onClose: () => void;
  onSubmitComplete: () => void;
}) {
  const { activeWatershedEmployee } = useAdminContext();
  switch (kind) {
    case 'createOrg':
      return <AddOrganizationDialog {...props} />;
    case 'createDemoOrg':
      return (
        <CreateDemoOrgDialog
          {...props}
          userId={activeWatershedEmployee.user.id}
        />
      );
    case null:
      return null;
  }
}

function FavoriteShareActions() {
  const { favoriteOrgIds, addFavoriteOrgs } = useFavoriteOrgs();
  const { enqueueSnackbar } = useSnackbar();
  const serializedOrgIds = favoriteOrgIds.join(',');
  const tryParseOrgIds = (value: string) => {
    // Just a baby sanity check on org_XXX
    const orgIds = value
      .split(',')
      .map((value) => value.trim())
      .filter((orgId) => orgId.startsWith('org_'));
    if (orgIds.length === 0) {
      return {
        success: false,
        message: 'No valid org ids found',
      };
    }
    return {
      success: true,
      orgIds,
    };
  };
  return (
    <Stack direction="row" gap={1}>
      <Button
        startIcon={<ShareIcon />}
        onClick={() => {
          copyToClipboard(serializedOrgIds);
          // Alert instead of snackbar to be more clear
          alert('Copied to clipboard! Send it to someone for import :)');
        }}
        disabled={favoriteOrgIds.length === 0}
        size="small"
      >
        Share favorites
      </Button>
      <Button
        startIcon={<UploadIcon />}
        onClick={() => {
          const value = prompt('Paste in the set of org ids (comma separated)');
          if (!value) return;
          const res = tryParseOrgIds(value ?? '');
          if (res.success && res.orgIds) {
            enqueueSnackbar('Imported org favorites!', { variant: 'success' });
            addFavoriteOrgs(res.orgIds);
          } else {
            enqueueSnackbar(res.message, { variant: 'error' });
          }
        }}
        size="small"
      >
        Import favorites
      </Button>
    </Stack>
  );
}

function ReferenceOrgsSection() {
  const [referenceOrgGroup, setReferenceOrgGroup] =
    useQueryParam('referenceOrgGroup');
  const referenceOrgGroups = useReferenceOrgs();
  const currentGroup =
    referenceOrgGroups.find((group) => group.name === referenceOrgGroup) ??
    referenceOrgGroups.at(0);
  return (
    <Card>
      <CardHeader
        title="Reference orgs"
        titleTypographyProps={{
          variant: 'h3',
        }}
        subheader={
          <Typography variant="body3" color="textSecondary">
            Personal favorites (starred orgs) and key accounts - request changes
            in{' '}
            <TextLink href="https://watershedclimate.slack.com/archives/C07C3A0JD33">
              #reference-org-requests
            </TextLink>
            !
          </Typography>
        }
        sx={{
          // Reduce padding to make more dense
          paddingTop: 1.5,
          paddingX: 1.5,
          paddingBottom: 0,
        }}
        action={<FavoriteShareActions />}
      />
      <Box borderBottom={(theme) => `1px solid ${theme.palette.border}`}>
        <Tabs
          value={currentGroup?.name}
          onChange={(_, value) => setReferenceOrgGroup(value)}
          sx={{
            paddingX: 2,
          }}
        >
          {referenceOrgGroups.map((orgGroup) => {
            return (
              <Tab
                key={orgGroup.name}
                label={orgGroup.name}
                value={orgGroup.name}
                sx={{
                  // Reduce padding to make more dense
                  paddingY: 1,
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      <Stack
        sx={{
          background: (theme) => theme.palette.grey10,
          paddingX: 2,
          paddingY: 1,
        }}
        gap={1}
      >
        {currentGroup?.warning ? (
          <Callout
            IconComponent={WarningIcon}
            variant="warning"
            title={currentGroup.warning.title}
            description={currentGroup.warning.description}
          />
        ) : null}
        {currentGroup?.orgs.length ? (
          <Stack
            direction="row"
            gap={1}
            flexWrap="wrap"
            key={currentGroup.name}
          >
            {currentGroup.orgs.map((org) => {
              return (
                <Card
                  key={org.orgId}
                  sx={{
                    width: 280,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CardActionArea
                    href={urlForObject('Organization', org.orgId)}
                    sx={{
                      flex: 1,
                      maxHeight: 180,
                      overflow: 'auto',
                    }}
                  >
                    <CardContent
                      sx={{
                        // Reduce padding to make more dense
                        padding: 1.5,
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={0.5}
                        justifyContent="space-between"
                      >
                        <Typography variant="h4">{org.orgName}</Typography>
                        <FavoriteOrgButton orgId={org.orgId} />
                      </Stack>
                      <Typography variant="body3">{org.description}</Typography>
                      <Stack
                        direction="row"
                        marginTop={1}
                        gap={0.5}
                        flexWrap="wrap"
                      >
                        {org.labels.map((label) => {
                          return (
                            <Chip
                              key={label.label}
                              size="small"
                              sx={{
                                background: (theme) =>
                                  (theme.palette[label.color] as
                                    | string
                                    | undefined) ?? theme.palette.lightGrey,
                                color: (theme) => theme.palette.white,
                              }}
                              label={label.label}
                            />
                          );
                        })}
                      </Stack>
                    </CardContent>
                  </CardActionArea>
                  <CardActions
                    sx={{
                      borderTop: (theme) => `1px solid ${theme.palette.border}`,
                      justifyContent: 'space-between',
                      padding: 0.5,
                    }}
                  >
                    <LoginAsButtons targetOrgId={org.orgId}>
                      Login
                    </LoginAsButtons>
                    <OrgQuickLinks
                      abbreviated
                      org={{
                        id: org.orgId,
                        name: org.orgName,
                      }}
                    />
                  </CardActions>
                </Card>
              );
            })}
          </Stack>
        ) : (
          currentGroup?.emptyState
        )}
      </Stack>
    </Card>
  );
}

export default function OrganizationListPage() {
  useOwner(Teams.EnterpriseFoundations);
  const router = useRouter();
  const [dialogKind, setDialogKind] = useState<DialogKind | null>(null);
  const [referenceOrgsOpen, toggleReferenceOrgs] = useToggle(true);
  const [showAllOrgsParam, setShowAllOrgs] = useQueryParam('showAllOrgs');
  const showAllOrgs = showAllOrgsParam === 'yes';

  const gridColumns = useOrgListColumns();

  const [result, executeRefresh] = useAllOrganizationsQuery();
  const { favoriteOrgIds } = useFavoriteOrgs();
  const favoriteOrgIdsSet = new Set(favoriteOrgIds);

  const data = getGqlResultData(result);
  const orgs = flattenConnection(data?.organizations)
    .filter((org) =>
      showAllOrgs || favoriteOrgIdsSet.has(org.id) ? true : org.demoOrg
    )
    .sort(
      (a, b) => +favoriteOrgIdsSet.has(b.id) - +favoriteOrgIdsSet.has(a.id)
    );

  const loading = isFetchingOrStale(result);

  return (
    <AdminPageContainer
      breadcrumbs={[{ title: 'Organizations' }]}
      bodySx={{
        display: 'grid',
        gridTemplateRows: referenceOrgsOpen ? 'auto 1fr' : '1fr',
        height: 'calc(100vh - 50px)',
      }}
    >
      <Collapse
        in={referenceOrgsOpen}
        unmountOnExit
        timeout={{
          enter: 500,
          exit: 300,
        }}
        easing={{
          enter: 'ease-in-out',
          exit: 'ease-in-out',
        }}
      >
        <ReferenceOrgsSection />
      </Collapse>
      <Box
        sx={{
          // this is necessary because of datagrid weirdness, it'll keep
          // expanding if you don't set these
          minWidth: 0,
          minHeight: 0,
        }}
      >
        <ObjectList<GQOrganizationListPageOrganizationFragment>
          rows={orgs}
          dense
          // this makes it so that when the collapsible closes, the grid doesn't
          // have a bunch of empty space cuz rows are still in the DOM
          rowBuffer={10}
          loading={loading}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showAllOrgs,
              setShowAllOrgs,
              setDialogKind,
              referenceOrgsOpen,
              toggleReferenceOrgs,
            },
          }}
          onRowClick={(row, event) =>
            navigateToPathOnClick(
              event,
              router,
              urlForObject('Organization', row.id.toString())
            )
          }
          sx={{
            '& .MuiDataGrid-cell:hover': {
              cursor: 'pointer',
            },
          }}
          getRowId={(row) => row.id.toString()}
          columns={gridColumns}
        />
      </Box>
      <DialogController
        kind={dialogKind}
        onClose={() => {
          setDialogKind(null);
        }}
        onSubmitComplete={() => {
          executeRefresh({ requestPolicy: 'cache-and-network' });
          setDialogKind(null);
        }}
      />
    </AdminPageContainer>
  );
}

function GridToolbar({
  showAllOrgs,
  setShowAllOrgs,
  setDialogKind,
  referenceOrgsOpen,
  toggleReferenceOrgs,
}: {
  showAllOrgs: boolean;
  setShowAllOrgs: (arg: string | null) => void;
  setDialogKind: (arg: DialogKind) => void;
  referenceOrgsOpen: boolean;
  toggleReferenceOrgs: () => void;
}) {
  const filterRef = useRef<HTMLInputElement | null>(null);

  useKeydown((e) => {
    // Focus the filter input on cmd+f or ctrl+f
    if ((e.metaKey || e.ctrlKey) && e.key === 'f' && filterRef.current) {
      e.preventDefault();
      filterRef.current.focus();
    }
  });

  return (
    <GridToolbarContainer
      sx={{
        gap: 1,
        marginBottom: -1,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Button
        startIcon={referenceOrgsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        onClick={toggleReferenceOrgs}
      >
        Reference orgs
      </Button>
      <Stack direction="row" sx={{ gap: 1 }}>
        <GridToolbarQuickFilter
          autoFocus
          inputProps={{ ref: filterRef }}
          fsUnmask
          sx={{ minWidth: 360 }}
        />
        <Button
          onClick={() =>
            showAllOrgs ? setShowAllOrgs(null) : setShowAllOrgs('yes')
          }
          tooltip="Tip: use Cmd+K to quickly jump between orgs"
        >
          {showAllOrgs ? 'Hide non-demo orgs' : 'Show all orgs'}
        </Button>
        <MoreActionsButton
          tooltip="Create a new organization"
          icon={<AddIcon />}
        >
          <MenuItem onClick={() => setDialogKind('createOrg')}>
            Create organization
          </MenuItem>
          <MenuItem onClick={() => setDialogKind('createDemoOrg')}>
            Create demo organization
          </MenuItem>
        </MoreActionsButton>
      </Stack>
    </GridToolbarContainer>
  );
}

const FavoriteCell: React.FC<{ orgId: string }> = (props) => {
  return (
    <Box padding={0.5}>
      <FavoriteOrgButton orgId={props.orgId} />
    </Box>
  );
};

function useOrgListColumns(): Array<
  GridColDef<GQOrganizationListPageOrganizationFragment>
> {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { activeWatershedEmployee } = useAdminContext();
  const accessibleOrgIds = activeWatershedEmployee.user.accessibleOrgs.map(
    (org) => org.id
  );
  const shouldRenderPreviewDeployOptions = getCurrentDevEnv() !== 'local-dev';

  return [
    {
      field: 'favorite',
      headerName: '',
      renderCell: ({ row }) => {
        return <FavoriteCell orgId={row.id} />;
      },
      sortable: false,
      disableColumnMenu: true,
      width: 32,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      maxWidth: 280,
      renderCell: ({ row }) => <ObjectList.TitleCell title={row.name} />,
    },
    {
      field: 'kind',
      headerName: 'Kind',
      flex: 0.5,
      minWidth: 160,
      maxWidth: 320,
      renderCell: ({ row }) => <OrgKindPillList {...row} />,
    },
    {
      field: 'loginAs',
      headerName: 'Login as',
      minWidth: 196,
      renderCell: ({ row }) => (
        <LoginAsButtons
          targetOrgId={row.id}
          disabled={!row.hasUsers}
          // we can hide these because we're showing them in the overflow menu
          hidePreviewDeployOptions
          size="large"
        >
          Login
        </LoginAsButtons>
      ),
    },
    {
      field: 'domains',
      headerName: 'Domains',
      flex: 1,
      renderCell: ({ row }) => (
        <OverflowChipList
          items={row.domains.map((domain) => ({ label: domain }))}
          // no need to render the popover if there's only one
          alwaysRenderOverflow={false}
          itemDisplay={{
            type: 'popover',
            renderPopover: () => (
              <Box
                sx={{
                  padding: 2,
                  width: 320,
                }}
              >
                <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
                  {row.domains.map((domain) => (
                    <Pill key={domain} label={domain} />
                  ))}
                </Stack>
              </Box>
            ),
          }}
        />
      ),
    },
    getOverflowMenuColumn(({ row }) => {
      const hasAccessToOrg = accessibleOrgIds.includes(row.id);
      return {
        dropdownItems: [
          ...(shouldRenderPreviewDeployOptions
            ? previewDeployNames.map((name) => ({
                id: name,
                label: `Login to ${name}`,
                onSelect: () =>
                  hasAccessToOrg
                    ? navigateToPathOnCallback(
                        router,
                        adminLoginAsMyselfUrl(row.id, {
                          previewDeployName: name,
                        })
                      )
                    : navigateToPathOnCallback(
                        router,
                        adminLoginAsUrl({
                          orgId: row.id,
                          previewDeployName: name,
                        })
                      ),
              }))
            : []),
        ],
        actions: [
          {
            id: 'copy',
            tooltip: 'Copy org ID to clipboard',
            IconElement: CopyIcon,
            onClick: () => {
              copyToClipboard(row.id);
              enqueueSnackbar(`Copied Org ID to clipboard`, {
                variant: 'success',
              });
            },
          },
        ],
      };
    }),
  ];
}

function OrgKindPillList(props: {
  name: string;
  demoOrg: boolean;
  testOrg: boolean;
  stagingOrg: boolean | null;
  canAccessFinance: boolean | null;
  watershedPlanLegacy?: GQWatershedPlanLegacy;
}) {
  // let's not get rid of the pointer when you hover
  const sx = {
    cursor: 'pointer',
  };
  const pills: Array<React.ReactNode> = [];
  if (!props.testOrg && !props.demoOrg) {
    pills.push(<Pill label="Customer" color="error" sx={sx} />);
  }
  if (props.testOrg) {
    pills.push(<Pill label="Test" sx={sx} />);
  }
  if (props.demoOrg) {
    pills.push(<Pill label="Demo" color="warning" sx={sx} />);
  }
  if (props.watershedPlanLegacy === GQWatershedPlanLegacy.NoPlan) {
    pills.push(<Pill label="Supplier" color="decorative" sx={sx} />);
  }
  if (props.stagingOrg) {
    pills.push(<Pill label="Staging" color="primary" sx={sx} />);
  }
  if (props.canAccessFinance) {
    pills.push(<Pill label="Finance" color="success" sx={sx} />);
  }
  return (
    <Stack direction="row" gap={1}>
      {pills}
    </Stack>
  );
}
