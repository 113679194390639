import { z } from 'zod';
import { AppType, ContentSecurityPolicyFlavor } from './constants';

// TODO (Julia Wang): Delete the default `us` region once we've added the REGION
// env var to all services. (We'll get that for free by migrating services to
// Terraform.)
export const RegionEnum = z.enum(['us', 'eu']);
export const RegionSchema = RegionEnum.default('us');
export type Region = z.infer<typeof RegionEnum>;

// NOTE(wc): there are many settings here that are too
// web-app specific and we should likely factor them out.
export interface ApplicationConfig {
  APPLICATION_NAME: string;
  APPLICATION_VERSION: string;
  SKIP_NEXT?: boolean;
  PORT: number;
  STATIC_PATH: string;
  NEXT_STATIC_PATH: string;
  ENFORCE_ORG_SCOPING: boolean;
  SESSION_KEY: string;
  SESSION_COOKIE_NAME: string;
  SESSION_COOKIE_DOMAIN?: string;
  EXTEND_SESSION_WHILE_ACTIVE?: boolean;
  MAX_SESSION_AGE?: number;
  CSP_FLAVOR?: ContentSecurityPolicyFlavor;
}

export interface PostgresConfig {
  DB_HOST: string;
  DB_NAME: string;
  DB_USER: string;
  DB_PASSWORD: string;
  DB_USE_SSL: boolean;
  DB_ALLOW_SELF_SIGNED_CERTIFICATES: boolean;
  DB_POOL_MIN: number;
  DB_POOL_MAX: number;

  GLOBAL_DB_HOST: string;
  GLOBAL_DB_NAME: string;
  GLOBAL_DB_PASSWORD: string;
  GLOBAL_DB_USER: string;
}

export interface GoogleStorageConfig {
  // https://www.notion.so/GCS-Buckets-d77901fac4e040a3a1f2f8709b52f235
  GCP_STORAGE_BUCKET_ADMIN: string;
  GCP_STORAGE_BUCKET_DASHBOARD: string;
  GCP_STORAGE_BUCKET_UPLOADS: string;
  GCP_STORAGE_BUCKET_GLOBAL: string;
  GCP_STORAGE_BUCKET_PUBLIC: string;
  GCP_STORAGE_BUCKET_MEASUREMENT: string;
  GCP_STORAGE_BUCKET_FIXTURES: string;
  GCP_STORAGE_BUCKET_TRACE: string;

  GCP_STORAGE_BUCKET_ADMIN_EU: string;
  GCP_STORAGE_BUCKET_DASHBOARD_EU: string;
  GCP_STORAGE_BUCKET_UPLOADS_EU: string;
  GCP_STORAGE_BUCKET_MEASUREMENT_EU: string;
  GCP_STORAGE_BUCKET_TRACE_EU: string;

  GCP_USER_UPLOADS_PUBSUB: string;
}

export interface TemporalClientConfig {
  TEMPORAL_ADDRESS: string;
  TEMPORAL_WEB_ADDRESS: string;
  TEMPORAL_NAMESPACE_ID: string;
  tls?: {
    clientCertPair: {
      crt: Buffer;
      key: Buffer;
    };
  };
}

export interface WorkOsConfig {
  WORKOS_API_KEY: string;
  WORKOS_CLIENT_ID: string;
  WORKOS_WEBHOOK_SECRET: string;
}

export interface QuickbooksConfig {
  QUICKBOOKS_CLIENT_ID: string;
  QUICKBOOKS_CLIENT_SECRET: string;
  QUICKBOOKS_ENV: string;
  QUICKBOOKS_API_URL: string;
}

export interface MergeConfig {
  MERGE_API_KEY: string;
}

// OneSchema template
export interface OneSchemaConfig {
  ONE_SCHEMA_VALIDATION_WEBHOOK_SECRET: string;
  ONE_SCHEMA_EVENTS_WEBHOOK_SECRET: string;
  ONE_SCHEMA_EXTERNAL_API_KEY: string;
  ONE_SCHEMA_CLIENT_ID: string;
  ONE_SCHEMA_CLIENT_SECRET: string;
}

export interface SendgridConfig {
  SENDGRID_API_KEY: string;
  SENDGRID_EVENTS_WEBHOOK_VERIFICATION_KEY?: string;
}

export interface SalesforceConfig {
  SALESFORCE_CLIENT_ID: string;
  SALESFORCE_CLIENT_SECRET: string;
}

export enum HoneycombMode {
  None = 'none',
  DemoPlatformOnly = 'demo-platform-only',
  All = 'all',
}

export interface HoneycombConfig {
  HONEYCOMB_MODE: HoneycombMode;
  HONEYCOMB_KEY: string;
}

export interface CdpApiConfig {
  CDP_API_URL: string;
  CDP_API_KEY: string;
}

export interface DuckDbServerConfig {
  DUCKDB_SERVER_URL: string;
  DUCKDB_SERVER_EXTERNAL_URL: string;
  DUCKDB_SERVER_EXTRA_LARGE_URL: string;
  DUCKDB_SERVER_COMPUTE_URL: string;
  DUCKDB_SERVER_POSTGRES_URL: string;
  DUCKDB_SERVER_SPATIAL_URL: string;
  DUCKDB_SERVER_TEST_URL: string;
  DUCKDB_SERVER_LOCAL_URL: string;
}

export interface DuckDbInProcessConfig {
  DUCKDB_MEMORY_LIMIT_GB: number;
}

export interface MapboxConfig {
  MAPBOX_API_KEY: string;
}

export enum QueryGovernance {
  None,
  Warn,
  Error,
}

export enum CrossDatabaseTransactionGovernance {
  None,
  Warn,
  Error,
}

export interface CrowdInConfig {
  CROWDIN_API_KEY: string;
  CROWDIN_PROJECT_ID: string;
  CROWDIN_DYNAMIC_INCOMING_DIRECTORY_ID: string;
  CROWDIN_DYNAMIC_STRING_BUNDLE_ID: string;
  CROWDIN_STATIC_STRING_BUNDLE_ID: string;
}

export interface SentryConfig {
  SENTRY_DSN: string;
  SENTRY_ENABLED: boolean;
  SENTRY_TOKEN: string;
  SENTRY_ENV: string;
}

export interface BaseConfig {
  APPLICATION: AppType;

  // In development, the watershed username (i.e., the part before the @ sign in
  // their email) of the person whose dev environment this is.
  WS_USER: string | null;

  SEND_EMAILS: boolean;
  PRINT_DB_QUERIES: boolean;
  ENFORCE_QUERY_GOVERNANCE: QueryGovernance;
  ENFORCE_CROSS_DATABASE_TRANSACTION_GOVERNANCE: CrossDatabaseTransactionGovernance;

  SLACK_BOT_TOKEN_WS_BOT?: string;
  SLACK_EXTERNAL_BOT_TOKEN?: string;
  SLACK_USER_UPLOAD_WORKFLOW_WEBHOOK_URL?: string;
  SLACK_MARKETPLACE_WEBHOOK_URL?: string;
  SLACK_PENDING_UPLOAD_WEBHOOK_URL?: string;
  SLACK_INTEGRATION_BOT_WEBHOOK_URL?: string;

  // See
  // https://www.notion.so/watershedclimate/AI-Policy-b3d0b11177284f0a9259e922000d6898
  // before adding more usages of this config key.
  OPENAI_API_KEY_FOR_NON_CUSTOMER_DATA?: string;

  TRIPACTIONS_API_URL: string;
  TRIPACTIONS_API_URL_EU: string;

  README_JWT_SECRET: string;

  CONNECTION_ENCRYPTION_KEY: string;

  NEXT_HOSTNAME: string;

  DUST_API_KEY: string;
}

export type Config = BaseConfig &
  GoogleStorageConfig &
  PostgresConfig &
  QuickbooksConfig &
  MergeConfig &
  TemporalClientConfig &
  WorkOsConfig &
  HoneycombConfig &
  DuckDbServerConfig &
  DuckDbInProcessConfig &
  SendgridConfig &
  SalesforceConfig &
  OneSchemaConfig &
  MapboxConfig &
  CrowdInConfig &
  SentryConfig &
  CdpApiConfig;
